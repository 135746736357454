import React from 'react';
import {SiteMeta} from '../general/seo'
import {TopBar, BottomBar } from '../general/extremeBars'
import NavBar from '../general/navbar'
import Team from './team'
import { graphql } from 'gatsby'
import FactoryCapacity from '../addon/Facility'
import { GatsbyImage } from "gatsby-plugin-image";
import { FoodsGrid } from '../product/grids';

export const result = graphql`
query {
    datalogsJson{
     about_page{
         brand_paragraph{
            title
            title2
            content
            content2
            masonry {
                childImageSharp{
                    gatsbyImageData
                }
            }
        }
        our_story {
            title
            content
        }
        our_impact {
            title
            sub_title
            content {
              body
              tag
              image {
                childImageSharp {
                    gatsbyImageData
                }
              }
            }
          }
        about_paragraph {
         mission_title
         mission_content
         vision_title
         vision_content
         image {
           childImageSharp {
             gatsbyImageData
           }
         }
       }
       food_safety {
            title
            content
            images {
                childImageSharp {
                    gatsbyImageData
                }
            }
       }
       sub_company {
         title
         content {
           par
           function {
             title
             list
           }
           image {
             childImageSharp {
               gatsbyImageData
             }
           }
         }
       }
       why_us {
         title
         body
         image {
           childImageSharp {
             gatsbyImageData
           }
         }
       }
       awards {
         title
         contents {
           subtitle
           title
           image {
             childImageSharp {
               gatsbyImageData
             }
           }
         }
       }
       team {
         title
         content
         body {
            image {
                childImageSharp{
                    gatsbyImageData
                }
            }
            tag
         }
        }
    }
    } 
 }
`

export function AboutPage ({data}){
    
    // const PageData = data.allDatalogsJson.edges[2].node;

    const PageData = data.datalogsJson.about_page;

    return (
    <>
    <SiteMeta title="About Us - Psaltry International Company Limited" description="Official Psaltry Company Information Page"/>
    <div className="dark:bg-gray-800">
    <TopBar />
    <NavBar />
    <div className="py-20  md:px-20 md:mx-auto 2xl:mx-auto md:container lg:mx-auto">
            <div className="flex flex-col block mb-4">
            <span className="text-2xl font-light text-gray-600 dark:text-white">About Us</span> 
            {/* <span className="text-4xl font-semibold text-sgreen">Products</span> */}
            </div>
            {/* first column */}
            <div className="md:flex flex-wrap flex-row-reverse md:mx-auto mx-0 items-center my-2 w-full ">
                    <div className="xl:w-6/12 md:w-1/2 w-full">
                    <div role="heading" className="focus:outline-none md:w-full sm:hidden font-bold md:text-2xl text-2xl sm:text-lg leading-tight text-sgreen">
                            {PageData.brand_paragraph.title}
                        </div>
                        <div className="border-b-4 border-sgreen sm:hidden w-1/12 mt-2 mb-4" />
                        <div className="mx-4 px-10">
                        <div className="columns-2 gap-2 block w-fit h-full sm:h-fit sm:w-fit">
                            {PageData.brand_paragraph.masonry.map((image, index)=>{
                                return(
                                <GatsbyImage
                                image={image.childImageSharp.gatsbyImageData}
                                alt="masonry"
                                className="rounded-lg mb-2"
                                />
                                )
                            })}
                        </div>
                        </div>
                    </div>
                    <div className="xl:w-6/12 md:w-1/2 w-full">
                        <div>
                        <div role="heading" className="focus:outline-none md:w-full sm:block sm:mt-2 hidden font-bold md:text-2xl text-2xl sm:text-lg leading-tight text-sgreen">
                            {PageData.brand_paragraph.title}
                        </div>
                        <div className="border-b-4 border-sgreen sm:block hidden w-1/12 mx-4 sm:mt-2 sm:mb-4" />
                        {PageData.brand_paragraph.content.map((body, index) => {
                            return(
                        <p role="contentinfo" className="focus:outline-none text-base leading-6 px-4 mt-8 text-gray-600  text-justify dark:text-white">
                         {body}
                        </p>
                            )
                        })}
                        <div role="heading" className="focus:outline-none md:w-full block mt-4 mx-4 font-bold text-2xl leading-tight text-sgreen">
                            {PageData.brand_paragraph.title2}
                        </div>
                        <div className="border-b-4 border-sgreen block mx-4 w-1/12 mb-2" />
                        {PageData.brand_paragraph.content2.map((body, index) => {
                            return(<li key="index" class="list-disc my-2 px-4 text-gray-600 text-justify dark:text-white">{body}</li>)
                        })}                        
                    </div>
                    </div>
                </div>
    </div>
    {/* vision et mission */}
    <div className="md:mx-auto 2xl:mx-auto md:container lg:mx-auto">
    <div className="md:flex flex-wrap flex-row-reverse md:mx-auto items-center my-2 w-full md:px-20 px-8">
                    <div className="xl:w-6/12 md:w-1/2 w-full">
                        <div className="mx-auto block w-full h-full">
                            <GatsbyImage
                                image={PageData.about_paragraph.image.childImageSharp.gatsbyImageData}
                                alt="man_brief_case"
                                className="rounded-lg mb-2 w-100"
                                />
                        </div>
                    </div>
                    <div className="xl:w-6/12 md:w-1/2 w-full">
                        <div className="mb-8">
                        <div role="heading" className="focus:outline-none md:w-full font-bold md:text-2xl text-2xl sm:text-lg leading-tight text-sgreen">
                            {PageData.about_paragraph.mission_title}
                        </div>
                        <div className="border-b-4 border-sgreen w-1/12 sm:my-2" />
                        <p role="contentinfo" className="focus:outline-none text-base leading-6 mt-2 text-gray-600 2xl:pr-24 xl:pr-0 text-justify dark:text-white">
                         {PageData.about_paragraph.mission_content}
                        </p>
                        </div>
                        <div>
                        <div role="heading" className="focus:outline-none md:w-full font-bold md:text-2xl text-2xl sm:text-lg leading-tight text-sgreen">
                            {PageData.about_paragraph.vision_title}
                        </div>
                        <div className="border-b-4 border-sgreen w-1/12 sm:my-2" />
                        <p role="contentinfo" className="focus:outline-none text-base leading-6 mt-2 text-gray-600 2xl:pr-24 xl:pr-0 text-justify dark:text-white">
                         {PageData.about_paragraph.vision_content}
                        </p>
                        </div>
                        </div>
                </div>
            </div>
{/* food safety policy */}
    <div className="bg-gray-50 w-full dark:bg-gray-600">
    <div className="py-8 lg:px-12 md:px-20 px-4 text-gray-900">
        <FoodsGrid data={PageData.food_safety} />
    </div>        
    </div>
            
    
{/* sub company */}

    <div className="bg-gray-200 w-full dark:bg-gray-500">
    <div className="py-20 lg:px-12 md:px-20 px-4 text-gray-900 dark:text-white">
    <div role="heading" className="focus:outline-none md:w-full font-bold text-2xl leading-tight text-sgreen dark:text-white">
                {PageData.our_story.title}
    <div className="w-1/2">
    <div className="border-b-4 border-sgreen dark:border-white w-1/12 sm:my-2" />
    </div>
    </div>
       {PageData.our_story.content.map((body, index) => {
        return(
        <p role="contentinfo" className="focus:outline-none text-base leading-6 mt-8 text-justify">
            {body}
        </p>
        )
    })}
    </div>
    </div>
    



     {/* third and impact row */}

     <div className="bg-gray-200 dark:bg-gray-400 w-full">
        <div className="py-20 lg:px-12 px-4 md:mx-auto text-gray-900 2xl:mx-auto md:container lg:mx-auto">
        <div role="heading" className="focus:outline-none md:w-full font-bold text-2xl leading-tight text-sgreen">
                {PageData.our_impact.title}
        </div>
        <div className="text-sm font-gray-400">{PageData.our_impact.sub_title}</div>
        
        {PageData.our_impact.content.map((body, index) => {
            return(
                <>
            <div className="flex flex-wrap">
            <p role="contentinfo" className="focus:outline-none text-base leading-6 mt-8 text-justify w-full md:w-4/6">
                <b>{body.tag}</b> {body.body}
            </p>
            <div className="hidden md:block w-1/6 rounded-lg p-2 mx-auto">
                <GatsbyImage 
                    image={body.image ? body.image.childImageSharp.gatsbyImageData : ""}
                    className="rounded-lg"
                />
            </div>
            </div>
            </>
            )
        })}
        <div className="block md:hidden w-fit p-2 mx-auto">
                <GatsbyImage 
                    image={PageData.our_impact.content[1].image.childImageSharp.gatsbyImageData}
                    className="rounded-lg"
                />
        </div>
         </div>
        </div>
        {/* Factory Capacity */}

        <FactoryCapacity />

      {/* grey backgroun starts */}
      <div className="py-20 lg:px-12 px-4 md:mx-auto 2xl:mx-auto md:container lg:mx-auto">
    <div className="md:flex flex-wrap md:mx-auto items-center my-2 w-full">
        <div className="w-full">
            <div role="heading" className="focus:outline-none md:w-full sm:mt-2 font-bold md:text-2xl text-2xl sm:text-lg leading-tight text-sgreen">
                {PageData.awards.title}
            </div>
            <div className="border-b-4 border-sgreen w-1/12 sm:my-2" />
        </div>
        <div className="flex flex-wrap w-full mt-6 mx-0 md:mx-28">
            {PageData.awards.contents.map((body, index) => {
                return(
                <div key={index} className="md:w-4/12 lg:w-4/12 xl:w-4/12 md:mx-0 mx-auto  block sm:flex-col">
                <GatsbyImage
                    alt="file_name"
                    className=""
                    image={body.image.childImageSharp.gatsbyImageData}
                />
                {/* <div className="text-2xl text-sgreen w-full text-center">{body.title}</div>
                <div className="text-base italic w-full text-center">{body.subtitle}</div> */}
            </div>
             )
            })}
            
        </div>
    </div>
    </div>


    {/* grey bar ends */}
    <div className="py-20 lg:px-12 px-4 md:mx-auto 2xl:mx-auto md:container lg:mx-auto">
    <div className="md:flex flex-wrap md:mx-auto items-center my-2 w-full">
        <div className="w-full">
            <div role="heading" className="focus:outline-none md:w-full sm:mt-2 font-bold md:text-2xl text-2xl sm:text-lg leading-tight text-sgreen">
             Awards
            </div>
            <div className="border-b-4 border-sgreen w-1/12 sm:my-2" />
        </div>
        <div className="flex flex-wrap w-full mt-6 dark:text-white">
            Coming Soon
            {/* {PageData.awards.contents.map((body, index) => {
                return(
                <div key={index} className="md:w-4/12 lg:w-4/12 xl:w-4/12  mx-auto block sm:flex-col">
                <GatsbyImage
                    alt="file_name"
                    className="w-full"
                    image={body.image.childImageSharp.gatsbyImageData}
                />
                <div className="text-2xl text-sgreen w-full text-center">{body.title}</div>
                <div className="text-base italic w-full text-center">{body.subtitle}</div>
            </div>
             )
            })} */}
            
        </div>
    </div>
    </div>
    {/* team section */}
    <div className="w-full bg-gray-800 dark:bg-gray-700">
    <div className="py-20">
        <Team data={PageData.team} />
    </div>
    </div>
    </div>
    <BottomBar />
    <style>{`
    .bannerlee{
        background-image: url('assets/images/banner.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    `}</style>
    </>
    );
}


export default AboutPage;