import React from "react"
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";


export default function FactoryCapacity() {
    const Data = useStaticQuery(graphql`
          query{
            datalogsJson {
              about_page {
                factory_capacity{
                  title
                  prologue
                  image{
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  list
                }
              }
            }
          }
    `)
  
    const PageData = Data.datalogsJson.about_page.factory_capacity
  
    return (
      <>
        <div className="py-20 lg:px-12 px-4  w-full lg:mx-auto bg-gray-100 dark:bg-gray-600">
          <div className="md:flex flex-wrap md:mx-auto items-center my-2 w-full">
            <div className="xl:w-6/12 md:w-1/2">
              <div className="w-fit mx-auto mb-8">
                <GatsbyImage
                  image={PageData.image.childImageSharp.gatsbyImageData}
                  alt="trinity_picture"
                  className=""
                />
              </div>
            </div>
            <div className="xl:w-6/12 md:w-1/2 w-full">
              <div
                role="heading"
                className="focus:outline-none md:w-full sm:mt-2 font-bold md:text-2xl text-2xl sm:text-lg leading-tight text-sgreen"
              >
                {PageData.title}
              </div>
              <div className="border-b-4 border-sgreen sm:block w-3/12 my-2" />
              {PageData.prologue.map((body, index) => {
                return (
                  <p
                    role="contentinfo"
                    className="focus:outline-none text-base leading-6 mt-8 text-gray-600 2xl:pr-24 xl:pr-0 text-justify dark:text-white"
                  >
                    {body}
                  </p>
                );
              })}
              <ul>
                {PageData.list.map((body, index) => {
                  return (
                    <li key="index" class="list-disc my-2 dark:text-white">
                      {body}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }