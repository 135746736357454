import React from "react";
import {GatsbyImage} from "gatsby-plugin-image"


function Index({data}) {
    return (
        <div>
            <div className="md:mx-24 mx-2 content-center text-white pt-12">
                <div className="block">
                    <h1 className="xl:text-4xl text-3xl text-white font-extrabold pb-6">{data.title}</h1>
                </div>
                <div className="text-md mb-4">{data.content}</div>

                <div className="columns-1 md:columns-2 gap-2 mb-2 md:pl-32 sm:pl-24 p-0">
                <div className="w-fit md:m-0 mx-auto flex-col flex md:mb-2">
                <GatsbyImage
                image={data.body[0].image.childImageSharp.gatsbyImageData}
                />

                <span className="mx-auto w-fit">{data.body[0].tag}</span>
                </div>
                <div className="w-fit md:m-0 mx-auto flex-col flex">
                <GatsbyImage
                image={data.body[2].image.childImageSharp.gatsbyImageData}
                />
                <span className="mx-auto w-fit">{data.body[2].tag}</span>
                </div>
                <div className="w-fit md:m-0 mx-auto flex-col flex">
                <GatsbyImage
                image={data.body[1].image.childImageSharp.gatsbyImageData}
                />
                <span className="mx-auto w-fit">{data.body[1].tag}</span>
                </div>
                <div className="w-fit md:m-0 mx-auto flex-col flex">
                <GatsbyImage
                image={data.body[3].image.childImageSharp.gatsbyImageData}
                />
                <span className="mx-auto w-fit">{data.body[3].tag}</span>
                </div>
                </div>
                {/* next break */}
                <div className="flex flex-wrap mb-2 md:pl-32 p-0">
                <div className="w-fit flex-col md:m-0 mx-auto  flex pr-0 md:pr-4">
                <GatsbyImage
                image={data.body[4].image.childImageSharp.gatsbyImageData}
                />
                <span className="mx-auto w-fit">{data.body[4].tag}</span>
                </div>
                <div className="w-fit flex-col md:m-0 mx-auto flex">
                <GatsbyImage
                image={data.body[5].image.childImageSharp.gatsbyImageData}
                />
                <span className="mx-auto w-fit">{data.body[5].tag}</span>
                </div>
                </div>
                <div className="flex flex-wrap">
                <div className="md:w-1/3 w-fit mx-auto flex-col flex pr-0 md:pr-4">
                <GatsbyImage
                image={data.body[6].image.childImageSharp.gatsbyImageData}
                className="rounded-lg"
                />
                <span className="mx-auto w-fit">{data.body[6].tag}</span>
                </div>
                <div className="md:w-1/3 w-fit mx-auto flex-col flex pr-0 md:pr-4">
                <GatsbyImage
                image={data.body[8].image.childImageSharp.gatsbyImageData}
                className="rounded-lg"
                />
                <span className="mx-auto w-fit">{data.body[8].tag}</span>
                </div>
                <div className="md:w-1/3 w-fit mx-auto h-fit flex-col flex pr-0 md:pr-4">
                <GatsbyImage
                image={data.body[7].image.childImageSharp.gatsbyImageData}
                className="rounded-lg"
                />
                <span className="mx-auto w-fit">{data.body[7].tag}</span>
                </div>
                </div>
            </div>
            
        </div>
    );
}

export default Index;
